<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<ng-container *ngIf="showBasicLogin">
  <h2>Not authorized</h2>
  <input type="password" placeholder="Password" [(ngModel)]="password" />
  <button (click)="authenticate()">Submit</button>
  <span *ngIf="isLoading">loading...</span>
</ng-container>

<ng-container *ngIf="authorized">
  <app-ga-loading-progress-bar></app-ga-loading-progress-bar>
  <app-top-navigation></app-top-navigation>

  <div #topOfPage></div>

  <router-outlet></router-outlet>
</ng-container>
<pre style="display: none">
  {{ (userContext.valueChanges | async)?.data | json }}
</pre>
